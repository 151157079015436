<template>
  <b-card border-variant="primary">
    <b-row>
      <b-col cols="12">
        <div style="float: left" class="flight-info text-align-left">
          {{ transportationSegment.transportNumber }}
          <br />
          <small>{{ transportationSegment.operator }}</small>
        </div>
        <div style="float: right">
          <b-button-group v-if="canEdit">
            <b-button
              variant="primary"
              @click="$emit('openEditFlightModal')"
              size="sm"
            >
              <feather-icon size="13" icon="Edit2Icon" />
            </b-button>
            <b-button
              variant="danger"
              @click="$emit('deleteSegment')"
              size="sm"
            >
              <feather-icon size="13" icon="TrashIcon" />
            </b-button>
          </b-button-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="text-align-left">
      <b-col cols="5">
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="airport-code">
              {{ transportationSegment.departureStation }}
            </div>
            <div class="airport-city">{{ airportsdetails.departure.name }}</div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="flight-time">
              {{
                this.$luxon(transportationSegment.departureDate, {
                  input: { format: "yyyy-MM-dd HH:mm", zone: "local" },
                  output: "HH:mm",
                })
              }}
            </div>
            <div class="flight-date">
              {{
                this.$luxon(transportationSegment.departureDate, {
                  input: { format: "yyyy-MM-dd HH:mm", zone: "local" },
                  output: "dd-MM-yy",
                })
              }}
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-row>
          <b-col cols="12">
            <div class="flight-info">
              {{ transportationSegment.transport_number }}
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="airport-code">
              {{ transportationSegment.arrivalStation }}
            </div>
            <div class="airport-city">{{ airportsdetails.arrival.name }}</div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="flight-time">
              {{
                this.$luxon(transportationSegment.arrivalDate, {
                  input: { format: "yyyy-MM-dd HH:mm", zone: "local" },
                  output: "HH:mm",
                })
              }}
            </div>
            <div class="flight-date">
              {{
                this.$luxon(transportationSegment.arrivalDate, {
                  input: { format: "yyyy-MM-dd HH:mm", zone: "local" },
                  output: "dd-MM-yy",
                })
              }}
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="transportType === 'coach'">
      <b-col cols="12">
        <div class="d-flex flex-row justify-content-between align-items-center mt-1">
          <h5 class="text-align-left mb-0">Points d'embarquement</h5>
          <b-button
            v-if="canEdit"
            variant="outline-primary"
            @click="$emit('showBoardingPointModal')"
            size="sm"
            >Ajouter
          </b-button>
        </div>
      </b-col>
      <b-col v-if="transportationSegment.boardingPoints.length > 0" cols="12">
        <b-list-group
          v-for="boardingPoint in transportationSegment.boardingPoints"
          :key="boardingPoint.id"
        >
          <b-list-group-item
            class="mt-1 d-flex flex-row justify-content-between align-items-center"
          >
            {{ boardingPoint.name }}
            <div>
              <span class="pr-1 font-weight-bold">
                {{ boardingPoint.time }}
              </span>

              <!-- <b-button-group v-if="canEdit"> -->
              <!-- <b-button
                  @click="$emit('showBoardingPointModal', boardingPoint)"
                  variant="primary"
                  size="sm"
                >
                  <feather-icon size="13" icon="Edit2Icon" />
                </b-button> -->

              <b-button
                v-if="canEdit"
                @click="$emit('deleteBoardingPoint', boardingPoint)"
                variant="danger"
                size="sm"
              >
                <feather-icon size="13" icon="TrashIcon" />
              </b-button>
              <!-- </b-button-group> -->
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col v-else>
        <p class="text-muted text-align-left">
          <i>Aucun point d'embarquement</i>
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BButtonGroup,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { useTravelInformationApi } from "@/modules/group/composables/use-travel-information-api";
import { onMounted, reactive } from "@vue/composition-api";
import { useApplicationContext } from "@/shared/composables/use-application-context";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    BListGroup,
    BListGroupItem,
  },
  props: {
    transportType: String,
    transportationSegment: Object,
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      getAirlineInformationMatchingOperator,
      getAirportsMatchingIataCode,
    } = useTravelInformationApi();
    let airportsdetails = reactive({
      departure: {},
      arrival: {},
    });
    const { forceUpdate } = useApplicationContext();

    const getAirportDetail = async (airport, direction) => {
      try {
        const airports = await getAirportsMatchingIataCode(airport);
        Object.assign(airportsdetails[direction], airports[0]);
        forceUpdate();
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      getAirportDetail(
        props.transportationSegment.departureStation,
        "departure"
      );
      getAirportDetail(props.transportationSegment.arrivalStation, "arrival");
    });

    return {
      getAirportsMatchingIataCode,
      airportsdetails,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
};
</script>

<style scoped>
.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-white {
  color: white;
}

.flight-info {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
}

.airport-code {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}

.flight-time {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
}

.airport-city,
.flight-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  margin-top: 2px;
}

.small-top-padding {
  padding: 0;
  padding-top: 10px;
}
</style>
