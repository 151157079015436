<template>
  <validation-observer ref="refFormObserver">
    <b-modal
      id="boarding-point-modal"
      title="Point d'embarquement"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      size="lg"
      @ok="submit"
      @show="fillDataIfProvided"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <required-text-input-field
              v-model="boardingPoint.name"
              name="name"
              label="Nom du point d'embarquement"
              label-for="name"
              placeholder="Nom du point d'embarquement"
            />
          </b-col>

          <b-col md="6">
            <b-form-group label="Heure d'embarquement" label-for="time">
              <b-form-input
                id="time"
                type="time"
                v-model="boardingPoint.time"
                locale="fr"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BFormInvalidFeedback,
  BAlert,
} from "bootstrap-vue";

import { ValidationObserver } from "vee-validate";
import { useValidatorsWithAutoFormDataCleanUp } from "@/shared/composables/use-validators";
import { required } from "@validations";
import { reactive, computed, ref } from "@vue/composition-api";

import Ripple from "vue-ripple-directive";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BAlert,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BFormInvalidFeedback,
    ToastificationContent,
    ValidationObserver,
    RequiredTextInputField,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    currentBoardingPoint: {
      type: Object,
      default: () => {
        return {};
      },
    },
    action: {
      type: String,
      default: "create",
    },
    segmentId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { displayErrorMessage } = useToastNotification();

    let initialState = {
      name: null,
      time: null,
      transportation_segment_id: null,
    };

    const boardingPoint = reactive({ ...initialState });
    boardingPoint.transportation_segment_id = props.segmentId;

    const fillDataIfProvided = () => {
      if (props.action === "update") {
        Object.assign(boardingPoint, props.currentBoardingPoint);
      } else {
        Object.assign(boardingPoint, initialState);
      }
    };

    const submit = async (event) => {
      try {
        if (props.action === "update") {
          emit("edit", boardingPoint);
        } else {
          emit("create", boardingPoint);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const { refFormObserver } =
      useValidatorsWithAutoFormDataCleanUp(boardingPoint);

    return {
      submit,
      boardingPoint,
      fillDataIfProvided,
      refFormObserver,
      required,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
