<template>
  <b-card
    border-variant="primary"
    header="Primary"
    header-bg-variant="primary"
    align="center"
    header-tag="header"
    class="mt-2"
    no-body
  >
    <template #header>
      <h3 class="text-white">{{ index + 1 }}. {{ transportOption.name }}</h3>
      <b-button-group>
        <b-button variant="outline-light" v-if="canEdit" @click="$emit('edit')"
          >Modifier</b-button
        >
        <b-button v-b-toggle="'collapse-' + index" variant="outline-light"
          >Détails</b-button
        >
      </b-button-group>
    </template>

    <b-collapse :id="'collapse-' + index" class="p-1" visible>
      <b-row class="mb-2 mt-2">
        <b-col cols="2" class="text-align-left">
          <div>
            <h5 class=" ">Type de transport</h5>
            <b-card-text>{{ transportOptionType }}</b-card-text>
          </div>
        </b-col>

        <b-col cols="2" class="text-align-left">
          <div class="">
            <h5 class=" ">Inventaire</h5>
            <b-card-text>
              {{ transportOption.seats }}
            </b-card-text>
          </div>
        </b-col>
        <b-col
          v-if="transportOption.type === 'flight'"
          cols="2"
          class="text-align-left"
        >
          <div>
            <h5 class=" ">Sélection de siège</h5>
            <b-card-text>
              {{ seatSelection }}
            </b-card-text>
          </div>
        </b-col>
        <b-col
          v-if="transportOption.type === 'flight'"
          cols="2"
          class="text-align-left"
        >
          <div v-if="transportOption.tourOperator">
            <h5 class=" ">Tour opérateur</h5>
            <b-card-text>
              {{ tourOperator }}
            </b-card-text>
          </div>
        </b-col>
        <b-col v-else cols="2" class="text-align-left">
          <div v-if="transportOption.transporter">
            <h5 class=" ">Transporteur</h5>
            <b-card-text>
              {{ transportOption.transporter }}
            </b-card-text>
          </div>
        </b-col>
        <b-col cols="2" class="text-align-left">
          <div v-if="showContractNumber && transportOption.contractNumber">
            <h5>Numéro de contrat</h5>
            <b-card-text>
              {{ transportOption.contractNumber }}
            </b-card-text>
          </div>
        </b-col>
        <b-col
          cols="3"
          v-if="transportOption.seatReleaseDate"
          class="text-align-left"
        >
          <div>
            <h5 class=" ">Date limite - relâche inventaire</h5>
            <b-card-text>
              {{ transportOption.seatReleaseDate }}
            </b-card-text>
          </div>
          <div v-if="transportOption.paymentLimitDate" class="mt-1">
            <h5 class=" ">Date Limite - Paiement final</h5>
            <b-card-text>
              {{ transportOption.paymentLimitDate }}
            </b-card-text>
          </div>
        </b-col>
      </b-row>
      <slot name="footer" />
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BButtonGroup,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";

import groupCreationConfig from "@/@core/data/groupCreationConfig";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
  },
  props: {
    showContractNumber: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    transportOption: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      groupCreationConfig,
    };
  },
  computed: {
    transportOptionType() {
      return this.transportOption.type === "flight" ? "Vol" : "Navette/Autocar";
    },
    tourOperator() {
      return (
        this.groupCreationConfig.tourOperators.find(
          (x) => x.value === this.transportOption.tourOperator
        )?.text || this.transportOption.tourOperator
      );
    },
    seatSelection() {
      return (
        this.groupCreationConfig.seatsSelection.find(
          (x) => x.value === this.transportOption.seatSelection
        )?.text || this.transportOption.seatSelection
      );
    },
  },
};
</script>

<style>
.text-align-left {
  text-align: left;
}

.text-white {
  color: white;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
