<template>
  <validation-observer ref="refFormObserver" #default="{ passes }">
    <b-modal
      id="groupCreateTransportOptionsModal"
      ref="modal"
      title="Option de transport"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      size="lg"
      @show="fillDataIfProvided"
      @ok.prevent="passes(submitCreateNewTransportOption)"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col
            v-if="action == 'update' && !canBeDeleted"
            cols="12"
            class="mb-2"
          >
            <b-alert show variant="danger">
              <p class="p-1">
                Il ne doit pas rester de segment dans l'option de transport pour
                pouvoir la supprimer.
              </p>
            </b-alert>
          </b-col>
          <b-col v-if="action == 'update'" cols="12" class="mb-2">
            <b-button
              variant="danger"
              @click="deleteTransportOption"
              class="float-right"
              size="sm"
            >
              Supprimer
            </b-button>
          </b-col>
          <b-col md="6">
            <required-text-input-field
              v-model="transportation.name"
              name="transport-option-name"
              label="Nom de l'option de transport"
              label-for="transport-option-name"
              placeholder="Départ de Paris"
            />
          </b-col>
          <b-col md="6">
            <required-text-input-field
              v-model="transportation.seats"
              name="Inventaire"
              label="Inventaire"
              label-for="transport-options-seats"
              placeholder="232"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              v-if="transportType === 'flight'"
              label="Tour opérateur"
              label-for="tour-operator"
            >
              <v-select
                id="tour-operator"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupCreationConfig.tourOperators"
                :selectable="(option) => !option.value.includes('select_value')"
                :reduce="(option) => option.value"
                label="text"
                v-model="transportation.tourOperator"
              />
            </b-form-group>
            <b-form-group v-else label="Transporteur" label-for="transporter">
              <b-form-input
                v-model="transportation.transporter"
                name="Numéro de contrat"
                label="Numéro de contrat"
                label-for="contract-number"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Numéro de contrat" label-for="contract-number">
              <b-form-input
                v-model="transportation.contractNumber"
                name="Numéro de contrat"
                label="Numéro de contrat"
                label-for="contract-number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="transportType === 'flight'">
          <b-col md="6">
            <b-form-group
              label="Sélection de siège"
              label-for="transport-options"
            >
              <v-select
                id="group-type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupCreationConfig.seatsSelection"
                :selectable="(option) => !option.value.includes('select_value')"
                :reduce="(option) => option.value"
                label="text"
                v-model="transportation.seatSelection"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BTabs,
  BTab,
  BFormInvalidFeedback,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import groupCreationConfig from "@core/data/groupCreationConfig.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";
import { required } from "@validations";
import { ValidationObserver } from "vee-validate";
import { reactive, ref } from "@vue/composition-api/dist/vue-composition-api";

export default {
  components: {
    BCardCode,
    BAlert,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    RequiredTextInputField,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    currentTransportation: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    action: {
      type: String,
    },
    transportType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      groupCreationConfig,
      option: {
        name: null,
        type: null,
        seats: null,
        seatReleaseDate: null,
        paymentLimitDate: null,
        tourOperator: null,
        seatSelection: null,
        departures: [],
        returns: [],
        contractNumber: null,
        transporter: null,
      },
    };
  },
  setup(props, { emit }) {
    let canBeDeleted = ref(true);

    let initialState = {
      name: null,
      type: props.transportType,
      seats: null,
      seatReleaseDate: null,
      paymentLimitDate: null,
      tourOperator: null,
      seatSelection: props.transportType === 'coach' ? 'NONE' : null,
      departures: [],
      returns: [],
      contract_number: null,
      transporter: null,
    };

    const modal = ref(null);
    const transportation = reactive({ ...initialState });

    const fillDataIfProvided = () => {
      if (props.action === "update") {
        Object.assign(transportation, props.currentTransportation);
      } else {
        Object.assign(transportation, initialState);
      }
    };

    const deleteTransportOption = async (event) => {
      let departures = props.currentTransportation.departures;
      let returns = props.currentTransportation.return;
      if (
        (departures && departures.length > 0) ||
        (returns && returns.length > 0)
      ) {
        canBeDeleted.value = false;
      } else {
        canBeDeleted.value = true;
        emit("deleteTransportOption", transportation);
        modal.value.hide();
      }
    };

    const submitCreateNewTransportOption = async (event) => {
      try {
        if (props.action === "update") {
          emit("editTransportOption", transportation);
        } else {
          emit("createNewTransportOption", transportation);
        }
        modal.value.hide();
      } catch (e) {
        console.error(e);
      }
    };

    return {
      modal,
      fillDataIfProvided,
      submitCreateNewTransportOption,
      transportation,
      deleteTransportOption,
      canBeDeleted,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
