<template>
  <b-row class="match-height">
    <b-col lg="12" md="12">
      <CoachTransportationList :can-edit="canEdit" :group-id="currentGroupId" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BButtonGroup,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";

import CoachTransportationList from "@/modules/group/creation-wizard/groupCreateTransportOptions/CoachTransportationList.vue";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    CoachTransportationList,
  },
  props: ["currentGroupId", "canEdit"],
  data() {
    return {};
  },
  directives: {
    "b-toggle": VBToggle,
  },
};
</script>
