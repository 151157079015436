<template>
  <validation-observer ref="refFormObserver" #default="{ passes }">
    <b-modal
      id="modal-select2"
      ref="transportation-segment-modal"
      title="Ajouter un segment"
      ok-title="Enregistrer"
      cancel-title="Annuler "
      cancel-variant="outline-secondary"
      size="lg"
      @ok.prevent="validateForm"
      no-close-on-backdrop
      @show="fillDataIfProvided"
      @cancel="resetForm"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              v-if="transportType === 'flight'"
              label="Aéroport de départ"
              label-for="departure-airport"
            >
              <validation-provider
                #default="{ errors }"
                name="Aéroport de départ"
                rules="required"
              >
                <v-select
                  v-model="transportSegment.departureStation"
                  :options="airports"
                  label="label"
                  :reduce="(airport) => airport.iata"
                  @search="searchAirports"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  rules="required"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-else
              label="Point de départ"
              label-for="departure_station"
            >
              <validation-provider
                #default="{ errors }"
                name="Point de départ"
                rules="required"
              >
                <b-form-input
                  id="departure_station"
                  v-model="transportSegment.departureStation"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="transportType === 'flight'"
              label="Aéroport d'arrivée"
              label-for="arrival-airport"
            >
              <validation-provider
                #default="{ errors }"
                name="Aéroport de d'arrivée"
                rules="required"
              >
                <v-select
                  :options="airports"
                  label="label"
                  :reduce="(airport) => airport.iata"
                  v-model="transportSegment.arrivalStation"
                  @search="searchAirports"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-else
              label="Point d'arrivée"
              label-for="arrival_station"
            >
              <validation-provider
                #default="{ errors }"
                name="Point d'arrivée"
                rules="required"
              >
                <b-form-input
                  id="arrival_station"
                  v-model="transportSegment.arrivalStation"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Date et heure de départ"
              label-for="transportNumber"
            >
              <b-form-input
                id="transportNumber"
                class="mb-1"
                type="datetime-local"
                v-model="departureDate"
                locale="fr"
                max="2100-01-01T00:00"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Date et heure d'arrivée"
              label-for="arrival-date-time"
            >
              <b-form-input
                id="arrival-date-time"
                class="mb-1"
                type="datetime-local"
                v-model="arrivalDate"
                locale="fr"
                max="2100-01-01T00:00"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="transportType === 'flight'" md="6">
            <required-text-input-field
              v-model="transportSegment.transportNumber"
              :name="
                t('group.transport_options.flight_segment.transport_number')
              "
              :label="
                t('group.transport_options.flight_segment.transport_number')
              "
              label-for="transport-number"
              placeholder="TS123"
            />
          </b-col>
          <b-col v-else md="6">
            <b-form-group
              label="Numéro de navette/autocar"
              label-for="transportNumber"
            >
              <b-form-input
                v-model="transportSegment.transportNumber"
                id="transportNumber"
                class="mb-1"
                type="text"
                placeholder="TS123"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="transportType === 'flight'"
              label="Compagnie aérienne"
              label-for="tour-operator"
            >
              <validation-provider
                #default="{ errors }"
                name="Compagnie aérienne"
                rules="required"
              >
                <v-select
                  id="tour-operator"
                  :options="airlines"
                  label="name"
                  :reduce="(airline) => airline.name"
                  v-model="transportSegment.operator"
                  @search="searchAirline"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-else
              label="Compagnie de transport"
              label-for="operator"
            >
              <validation-provider
                #default="{ errors }"
                name="Compagnie de transport"
                rules="required"
              >
                <b-form-input
                  id="operator"
                  v-model="transportSegment.operator"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import dayjs from "dayjs";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import groupCreationConfig from "@core/data/groupCreationConfig.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useTravelInformationApi } from "@/modules/group/composables/use-travel-information-api";
import { VueAutosuggest } from "vue-autosuggest";
import { reactive } from "@vue/composition-api";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { useLocalisation } from "@/shared/composables/use-localisation";
import { useValidatorsWithAutoFormDataCleanUp } from "@/shared/composables/use-validators";

import RequiredTextInputField from "@/shared/components/RequiredTextInputField";
import RequiredSelectBox from "@/shared/components/RequiredSelectBox";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    ToastificationContent,
    RequiredSelectBox,
    RequiredTextInputField,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    transportType: {
      type: String,
      required: true,
    },
    currentTransportSegment: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      groupCreationConfig,
    };
  },
  setup(props, { emit }) {
    const { refOf, forceUpdate } = useApplicationContext();
    const {
      getAirlineInformationMatchingOperator,
      getAirportsMatchingIataCode,
    } = useTravelInformationApi();
    const { t } = useLocalisation();

    const initialState = {
      arrivalDate: "",
      departureDate: "",
      departureStation: "",
      operator: "",
      arrivalStation: "",
      transportNumber: "",
    };

    const transportSegment = reactive({ ...initialState });
    const airlines = reactive([]);
    const airports = reactive([]);

    const fillDataIfProvided = () => {
      if (props.action === "update") {
        Object.assign(transportSegment, props.currentTransportSegment);
      } else {
        Object.assign(transportSegment, initialState);
      }
    };

    const searchAirline = async (startingWith, loading) => {
      loading(true);
      const foundAirlines = await getAirlineInformationMatchingOperator(
        startingWith
      );
      airlines.splice(0);
      foundAirlines.forEach((e) => airlines.push(e));
      loading(false);
    };

    const searchAirports = async (iataCode, toggleLoading) => {
      toggleLoading(true);
      const foundAirports = await getAirportsMatchingIataCode(iataCode);
      airports.splice(0);
      foundAirports.forEach((e) => {
        e.label = `${e.iata} - ${e.name}`;
        airports.push(e);
      });
      toggleLoading(false);
    };

    const submitTransportSegment = () => {
      if (props.action === "update") {
        emit("edit", transportSegment);
      } else {
        emit("create", transportSegment);
      }
    };

    const { refFormObserver, resetForm } =
      useValidatorsWithAutoFormDataCleanUp(transportSegment);

    const validateForm = () => {
      refOf("refFormObserver")
        .validate()
        .then((success) => {
          if (success) {
            submitTransportSegment();
            refOf("transportation-segment-modal").hide();
          }
        });
    };

    return {
      transportSegment,
      airlines,
      airports,
      searchAirline,
      searchAirports,
      submitTransportSegment,
      initialState,
      refFormObserver,
      resetForm,
      fillDataIfProvided,
      t,
      validateForm,
    };
  },
  computed: {
    departureDate: {
      get() {
        const value = dayjs(
          this.transportSegment.departureDate,
          "YYYY-MM-DD HH:mm"
        );
        return value.format("YYYY-MM-DDTHH:mm");
      },
      set(value) {
        const newDate = dayjs(value, "YYYY-MM-DDTHH:mm");
        this.transportSegment.departureDate =
          newDate.format("YYYY-MM-DD HH:mm");
      },
    },
    arrivalDate: {
      get() {
        const value = dayjs(
          this.transportSegment.arrivalDate,
          "YYYY-MM-DD HH:mm"
        );
        return value.format("YYYY-MM-DDTHH:mm");
      },
      set(value) {
        const newDate = dayjs(value, "YYYY-MM-DDTHH:mm");
        this.transportSegment.arrivalDate = newDate.format("YYYY-MM-DD HH:mm");
      },
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
